import PostModel from '@/views/landing/blog/post/model/PostModel';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';
import { groupEncryptWithRSA } from '@lemontree-ai/lemontree-admin-common-front/utils/RsaUtils';
import {
  GET_REQUEST,
  POST_REQUEST,
  PUT_REQUEST,
  DELETE_REQUEST,
} from '@/services/http';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class PostDetailViewModel {
  constructor() {
    this.model = {};
    this.isModify = false;
    this.registerValid = {
      // thumbnail: {
      //   value: 'thumbnail',
      //   valueType: 'array',
      //   isValid: false,
      // },
      // shareThumbnail: {
      //   value: 'shareThumbnail',
      //   valueType: 'array',
      //   isValid: false,
      // },
      title: {
        value: 'title',
        valueType: '',
        isValid: false,
      },
      link: {
        value: 'link',
        valueType: 'url',
        isValid: false,
      },
      contents: {
        value: 'contents',
        valueType: '',
        isValid: false,
      },
    };
    this.isPostPreviewPopup = false;
  }

  init(link) {
    this.model = new PostModel();
    if (link) {
      this.isModify = true;
      this.getPostDetail(link);
    }
  }
  initCopyRegister(copyId) {
    this.model = new PostModel();
    this.getPostDetail(copyId);
  }
  onClickPostPreviewPopup() {
    this.isPostPreviewPopup = true;
  }
  onClickClosePostPreviewPopup() {
    this.isPostPreviewPopup = false;
  }

  registerValidatedResult() {
    return pageValidated(this.registerValid, this.model);
  }

  onClickDelete() {
    const requestDelete = () => {
      this.deletePostDetail();
    };
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `포스트를 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  // 이미지없으면 비활성화로만 등록가능
  setCheckImageAndStatus(data, type, callback) {
    if (
      (data.thumbnail.length === 0 || data.shareThumbnail.length === 0) &&
      data.status === 'NORMAL'
    ) {
      let msgText;
      switch (type) {
        case 'REGISTER': {
          msgText = '등록';
          break;
        }
        case 'MODIFY': {
          msgText = '수정';
          break;
        }
      }
      const onCallback = () => {
        this.model.setStatusHidden();
        callback();
      }
      store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `이미지 없이 ${msgText}시 비활성 상태로 ${msgText}됩니다`,
        closeBtnText: '취소',
        compeleteBtnText: '확인',
        confirmCallBack: onCallback,
      });
    }else{
      callback();
    }
  }
  // 상세
  getPostDetail(link) {
    const path = `${apiPath.POST_DETAIL.format(link)}`;
    GET_REQUEST(path).then(
      success => {
        const resultData = success.data;
        this.model.setData(resultData);
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // 등록
  postPostDetail() {
    const path = `${apiPath.POST_REGISTER}`;
    let data = this.model.getRegisterData();
    const callback = () =>{
      data = this.model.getRegisterData();
      POST_REQUEST(path, data).then(
        success => {
          const resultData = success.data;
          store.dispatch(
            'commonToast/fetchToastStart',
            '포스트가 등록되었습니다',
          );
          router.push({ name: 'LANDING_BLOG_POST_LIST' });
        },
        failed => {
          store.dispatch('commonToast/fetchToastStart', failed.msg);
        },
      );
    }
    this.setCheckImageAndStatus(data, 'REGISTER', callback);
  }
  // 수정
  putPostDetail() {
    const path = `${apiPath.POST_MODIFY.format(this.model.id)}`;
    let data = this.model.getModifyData();
    const callback = () => {
      data = this.model.getModifyData();
      PUT_REQUEST(path, data).then(
        success => {
          const resultData = success.data;
          store.dispatch(
            'commonToast/fetchToastStart',
            '포스트가 수정되었습니다',
          );
          router.push({ name: 'LANDING_BLOG_POST_DETAIL', params: {link : this.model.link} });
        },
        failed => {
          store.dispatch('commonToast/fetchToastStart', failed.msg);
        },
      );
    };
    this.setCheckImageAndStatus(data, 'MODIFY', callback);
  }
  // 삭제
  deletePostDetail() {
    const path = `${apiPath.POST_DELETE.format(this.model.id)}`;
    DELETE_REQUEST(path).then(
      success => {
        store.dispatch(
          'commonToast/fetchToastStart',
          '포스트가 삭제되었습니다',
        );
        router.push({ name: 'LANDING_BLOG_POST_LIST' });
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
}
